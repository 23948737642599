import React from "react";

const ReviewItem = ({ rating, message, date }) => {
  const getStars = () => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(
        <span key={i} className="star-icon">
          ⭐
        </span>
      );
    }
    return stars;
  };

  const formatDate = (epochTime) => {
    // Convert epoch time to Date object
    const date = new Date(epochTime * 1000);
    // Get current date
    const now = new Date();

    // Calculate the time difference in seconds
    const timeDiff = Math.floor((now - date) / 1000);

    // Logic to determine the relative time
    if (timeDiff < 60) {
      return `${timeDiff} seconds ago`;
    } else if (timeDiff < 3600) {
      return `${Math.floor(timeDiff / 60)} minutes ago`;
    } else if (timeDiff < 86400) {
      return `${Math.floor(timeDiff / 3600)} hours ago`;
    } else if (timeDiff < 2592000) {
      return `${Math.floor(timeDiff / 86400)} days ago`;
    } else {
      return date.toDateString(); // If older, show the full date
    }
  };

  return (
    <div className="review-item">
      <div className="rating-stars">{getStars()}</div>
      <p className="review-message">{message}</p>
      <p className="review-date">{formatDate(date)}</p>
    </div>
  );
};

export default ReviewItem;
