import React from "react";

const ProductItemPromotion = ({ name, description, imageUrl, id }) => {
  return (
    <div className="promo-item">
      <img src={imageUrl} alt={name} className="product-image" />
      <h3 className="product-name">{name}</h3>
      <p className="product-description">{description}</p>
      <button data-sellix-product={id} type="submit" className="buy-button">
        Buy
      </button>
    </div>
  );
};

export default ProductItemPromotion;
