import logo from "../logo.svg";
import React, { useState, useEffect, useRef } from "react";
import "../fonts/GT-Eesti-Display-Regular.otf";
import "./App.css";
import cookieMonsterImage from "../peekingCookieM.png";
import ProductItem from "../ProductItem";
import ProductItemPromotion from "../ProductItemPromotion";
const Sellix = require("sellix-api-wrapper");
const API = new Sellix.API(process.env.REACT_APP_SELLIX_PUBLIC_KEY);

function Home() {
  const colorScheme = {
    "--primaryColor_text": "#000000",
    "--secondary_text_color": "#898989",
    "--dark_color": "#ffffff",
    "--light_color": "#121212",
    "--white_color": "#1b1b1b",
    "--primaryColor": "linear-gradient(180deg, #b7d1ee 0%, #fbfbfb 100%)",
    "--primaryColor_transparent": "#b7d1ee04",
    "--primaryColor_main": "white",
    "--primaryColor_accent": "#fbfbfb",
  };

  const ref = useRef(null);
  const doClick = () => ref.current?.scrollIntoView({ behavior: "smooth" });

  const [products, setProducts] = useState([]); // Initialize with an empty array
  const [promotionalItems, setPromotionalItems] = useState([]);
  const [logProducts, setLogProducts] = useState([]);
  const [cookieProducts, setCookieProducts] = useState([]);
  const [others, setOthers] = useState([]);

  useEffect(() => {
    API.getAllProducts().then((response) => {
      const fetchedProducts = response.data.products.map((product) => ({
        name: product.title,
        description: product.description,
        imageUrl:
          "https://imagedelivery.net/95QNzrEeP7RU5l5WdbyrKw/" +
          product.cloudflare_image_id +
          "/shopItem",
        id: product.uniqid,
        categories: product.categories,
      }));
      console.log("Fetched " + fetchedProducts.length + " products...");

      // Filter promotional items
      const promoItems = fetchedProducts.filter((product) =>
        product.categories.some((category) => category.title === "PROMOTIONS")
      );

      const rdpItems = fetchedProducts.filter((product) =>
        product.categories.some((category) => category.title === "RDP")
      );

      const logItems = fetchedProducts.filter((product) =>
        product.categories.some((category) => category.title === "Logs")
      );

      const cookieItems = fetchedProducts.filter((product) =>
        product.categories.some((category) => category.title === "Cookies")
      );

      const otherItems = fetchedProducts.filter(
        (product) =>
          !rdpItems.includes(product) &&
          !promoItems.includes(product) &&
          !cookieItems.includes(product) &&
          !logItems.includes(product)
      );

      setProducts(rdpItems);
      setPromotionalItems(promoItems);
      setLogProducts(logItems);
      setCookieProducts(cookieItems);
      setOthers(otherItems);
    });
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.sellix.io/static/js/embed.js";
    script.async = true;
    document.head.appendChild(script);

    const styleLink = document.createElement("link");
    styleLink.href = "https://cdn.sellix.io/static/css/embed.css";
    styleLink.rel = "stylesheet";
    document.head.appendChild(styleLink);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(styleLink);
    };
  }, []);

  return (
    <div style={colorScheme} id="style-7">
      <div className="header-wrapper">
        <div className="header">
          <header className="header-inner">
            <div className="logo">
              <img src={logo} className="App-logo" alt="logo" />
              <span className="shop-name">TCB</span>
            </div>
            <div className="navbar">
              <a href="/">
                <button className="nav-button-active">Home</button>
              </a>
              <a href="/reviews">
                <button className="nav-button">Reviews</button>
              </a>
              <button className="nav-button">Contact</button>
            </div>
          </header>
        </div>
      </div>

      <div className="welcome-section">
        <h1 className="welcome-text">Welcome to The Cookie Box!</h1>
        <p className="welcome-subtext">
          TheCookieBox | Your #1 Cookies Supplier On The Market
        </p>
        <button className="go-shopping-button" onClick={doClick}>
          Go Shopping ➡
        </button>
      </div>

      <div
        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
      >
        <button
          className="category-button"
          onClick={() =>
            document
              .getElementById("rdps")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          RDPs
        </button>
        <button
          className="category-button"
          onClick={() =>
            document
              .getElementById("cookies")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Cookies
        </button>
        <button
          className="category-button"
          onClick={() =>
            document
              .getElementById("logs")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Logs
        </button>
        <button
          className="category-button"
          onClick={() =>
            document
              .getElementById("others")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Others
        </button>
      </div>

      <div className="separator" ref={ref} id="promotional-items">
        <h2>Promotional Items</h2>
      </div>

      <div className="products-container">
        {promotionalItems.map((product, index) => (
          <ProductItemPromotion
            key={index}
            name={product.name}
            description={product.description}
            imageUrl={product.imageUrl}
            id={product.id}
          />
        ))}
      </div>

      <div className="separator" id="rdps">
        <h2>RDPs</h2>
      </div>

      <div className="products-container">
        {products.map((product, index) => (
          <ProductItem
            key={index}
            name={product.name}
            description={product.description}
            imageUrl={product.imageUrl}
            id={product.id}
          />
        ))}
      </div>

      <div className="separator" id="cookies">
        <h2>Cookies</h2>
      </div>

      <div className="products-container">
        {cookieProducts.map((product, index) => (
          <ProductItem
            key={index}
            name={product.name}
            description={product.description}
            imageUrl={product.imageUrl}
            id={product.id}
          />
        ))}
      </div>

      <div className="separator" id="logs">
        <h2>Logs</h2>
      </div>

      <div className="products-container">
        {logProducts.map((product, index) => (
          <ProductItem
            key={index}
            name={product.name}
            description={product.description}
            imageUrl={product.imageUrl}
            id={product.id}
          />
        ))}
      </div>

      <div className="separator" id="others">
        <h2>Others</h2>
      </div>

      <div className="products-container">
        {others.map((product, index) => (
          <ProductItem
            key={index}
            name={product.name}
            description={product.description}
            imageUrl={product.imageUrl}
            id={product.id}
          />
        ))}
      </div>

      <div className="support-info">
        <div className="support-content">
          <h3>We’re here to help</h3>
          <p>
            If you need any assistance with a product you purchased or simply
            want to get in touch with us, feel free to reach out by clicking the
            button below.
          </p>
          <button className="contact-support-button">Contact Support</button>
        </div>
      </div>

      <img
        src={cookieMonsterImage}
        alt="Cookie Monster"
        className="cookie-monster-bg"
      />
    </div>
  );
}

export default Home;
