import logo from "../logo.svg";
import React, { useState, useEffect } from "react";
import "../fonts/GT-Eesti-Display-Regular.otf";
import "../App.css";
import ReviewItem from "../ReviewItem";
const Sellix = require("sellix-api-wrapper");
const API = new Sellix.API(process.env.REACT_APP_SELLIX_PUBLIC_KEY);

function Home() {
  const colorScheme = {
    "--primaryColor_text": "#000000",
    "--secondary_text_color": "#898989",
    "--dark_color": "#ffffff",
    "--light_color": "#121212",
    "--white_color": "#1b1b1b",
    "--primaryColor": "linear-gradient(180deg, #b7d1ee 0%, #fbfbfb 100%)",
    "--primaryColor_transparent": "#b7d1ee04",
    "--primaryColor_main": "white",
    "--primaryColor_accent": "#fbfbfb",
  };

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    API.getAllFeedback().then((response) => {
      const fetchedReviews = response.data.feedback.map((feedback) => ({
        message: feedback.message,
        rating: feedback.score,
        date: feedback.created_at,
      }));
      console.log("Fetched " + fetchedReviews.length + " reviews...");

      // Add the fetched reviews to the existing reviews
      setReviews([...fetchedReviews]);

      // Add the test review after fetching the reviews
      const testReview = {
        message: "This is a test review",
        rating: 5,
        date: "1691571513",
      };

      const testReview2 = {
        message: "This is a test review 2",
        rating: 5,
        date: "1691571513",
      };

      setReviews((prevReviews) => [...prevReviews, testReview, testReview2]);
    });
  }, []);

  return (
    <div style={colorScheme}>
      <div className="header-wrapper">
        <div className="header">
          <header className="header-inner">
            <div className="logo">
              <img src={logo} className="App-logo" alt="logo" />
              <span className="shop-name">TCB</span>
            </div>
            <div className="navbar">
              <a href="/">
                <button className="nav-button">Home</button>
              </a>
              <a href="/reviews">
                <button className="nav-button-active">Reviews</button>
              </a>
              <button className="nav-button">Contact</button>
            </div>
          </header>
        </div>
      </div>

      <div className="reviews-container">
        {reviews.map((review, index) => (
          <ReviewItem
            key={index}
            rating={review.rating}
            message={review.message}
            date={review.date}
          />
        ))}
      </div>
    </div>
  );
}

export default Home;
