import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Reviews from "./pages/Reviews";

function App() {
  return (
    <Router>
      <div>
        {/* Common header */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reviews" element={<Reviews />} />
        </Routes>

        {/* Common footer */}
      </div>
    </Router>
  );
}

export default App;
